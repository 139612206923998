<template>
  <section class="headerdashboard">
    <v-card class="mx-auto">
      <v-navigation-drawer class="navigationback" permanent>
        
        <template>
          <v-list>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard">
                  <v-list-item-title>Dashboard</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/emotions">
                  <v-list-item-title>Emotions</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/subemotions">
                  <v-list-item-title>Sub Emotions</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/lifetags">
                  <v-list-item-title>Life Tags</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/challenges">
                  <v-list-item-title>Challenges</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/communities">
                  <v-list-item-title>Community</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/prompter">
                  <v-list-item-title>Prompter</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/activities">
                  <v-list-item-title>Activity</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/notifications">
                  <v-list-item-title>Notification</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/users">
                  <v-list-item-title>Users</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/reports">
                  <v-list-item-title>Reports</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      <div>
        <v-btn block @click="logoutbutton" class="logoutbtn">
         <v-icon class="logout">mdi-power</v-icon> Logout
        </v-btn>
      </div>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  props: ["mini"],
  computed: mapGetters([]),
  data() {
    return {};
  },
  methods: {
    logoutbutton() {
      this.logout();
    },
    ...mapActions([ "logout"]),
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.left-fix {
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  .menuitems {
    color: $menulistcolor;
    font-size: $normalfont;
    display: block;
    text-decoration: none;
    padding: 5px 15px;
    margin-bottom: 5px;
    position: relative;
    // margin-left: 20px;
  }
  i {
    color: $menulistcolor;
    position: absolute;
    top: 18%;
    font-size: $iconfont;
  }
  .icon {
    &-left {
      left: -15px;
    }
    &-right {
      right: 0;
    }
  }
}
a {
  color: $dark !important;
  text-decoration: none;
  .v-list-item__title{
  font-size: $normalfont!important;
  }

}
.menulist {
  margin: 10px;
}
.navigationback {
  background: $menucolor !important;
}
.top-fix {
  position: fixed;
  left: 0;
  top: 0;
  height: 6%;
  background: $white;
  width: 85%;
  margin-left: 15%;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1;
}
ul {
  list-style: none;
  padding-left: 0;
}
.logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 150px;
  }
}
.tag {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: $tagcolor;
  display: block;
  font-size: $smallfont;
  padding: 3px 8px;
  color: $white;
  border-radius: 5px;
}
.right-actions {
  display: flex;
  justify-content: flex-end;
}
.headerdashboard {
  .v-sheet {
    box-shadow: none !important;
  }
}
.logoutbtn {
  position: fixed;
  bottom: 0px;
  left: 0;
  background: $tagcolor!important;
  color:$white!important;
  .v-icon{
    margin-right: 10px;
  }
  border-radius: 0!important;
}
</style>
