import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  notification: undefined,
  notifications: {
    results: [],
    pagination: {
      total: 0,
    }
  },
});

const getters = {
  allNotification: (state) => state.notification,
  allNotifications: (state) => state.notifications,
};

const actions = {
  async getNotifications({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}notification?${new URLSearchParams(data).toString()}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setNotifications", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async createNotification({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}notification`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push("/dashboard/notifications");
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async getNotification({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "notification/sendnotificationall/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setNotification", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async sendNotification({ commit }, id) {
    try {
      const response = await Axios.post(`${baseUrl}notification/${id}/send`, {}, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async deleteNotification(store, id) {
    const notifications = store.getters.allNotifications;
    try {
      const response = await Axios.delete(`${baseUrl}notification/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      notifications.results = notifications.results.filter(n => n._id !== id);
      store.commit("setNotifications", notifications);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
};

const mutations = {
  setNotification: (state, data) => (state.notification = data),
  setNotifications: (state, data) => (state.notifications = data),
};

export default { state, getters, actions, mutations };
