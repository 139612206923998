import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  prompter: {},
  prompters: {
    results: [],
    pagination: {
      total: 0,
    }
  },
});

const getters = {
  currentPrompter: (state) => state.prompter,
  allPrompters: (state) => state.prompters,
};

const actions = {
  async getPrompters({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}prompt?${new URLSearchParams(data).toString()}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setPrompters", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async getPrompter({ commit }, id) {
    try {
      const response = await Axios.get(`${baseUrl}prompt/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setPrompter", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async createPrompter({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}prompt`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/prompter');
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async updatePrompter({ commit }, { id, data }) {
    try {
      const response = await Axios.put(`${baseUrl}prompt/${id}`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/prompter');
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async deletePrompter(store, id) {
    const prompters = store.getters.allPrompters;
    try {
      await Axios.delete(`${baseUrl}prompt/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      prompters.results = prompters.results.filter((prompter) => prompter._id !== id);
      store.commit("setPrompters", prompters);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
};

const mutations = {
  setPrompter: (state, data) => (state.prompter = data),
  setPrompters: (state, data) => (state.prompters = data),
};

export default { state, getters, actions, mutations };
