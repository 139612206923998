import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  communities: {
    results: [],
    pagination: {
      total: 0,
    }
  },
});

const getters = {
  allCommunities: (state) => state.communities,
};

const actions = {
  async getCommunities({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}community?${new URLSearchParams(data).toString()}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setCommunities", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
};

const mutations = {
  setCommunities: (state, data) => (state.communities = data),
};

export default { state, getters, actions, mutations };
