<template>
  <section>
    <v-app>
      <v-navigation-drawer
        app
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        class="backmenu"
      >
        <v-list-item class="px-2">
          <v-list-item-title @click.stop="mini = !mini" class="logobox">
            <router-link to="/dashboard"><img src="@/assets/logo.png" /></router-link
          ></v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
          <Header />
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app>
        <ul class="rightsection">
          <span class="userIcon">{{ firstName[0] }}</span>
          {{
            firstName
          }}
        </ul>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view :key="$route.fullPath"></router-view>
        </v-container>
      </v-main>
      <v-footer app>
        {{ new Date().getFullYear() }} - Innate Mood Dashboard
      </v-footer>
      
    </v-app>
  </section>
</template>

<script>
import Header from "@/components/dashboard/Header";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Dashboard",
  components: {
    Header,
  },
  data: () => ({
    clipped: false,
    fixed: false,
    drawer: true,
    mini: false,
    first_name: null,
    last_name: null,
    firstName: null,
    snackbar: false,
    started: null,
  }),
  methods: {
    ...mapActions([
      "logout",
      "fillUserData",
      "fetchMenus",
      "updateAttendance",
      "createAttendance",
      "fetchAttendanceSingleUser",
    ]),
    logoutbutton() {
      this.logout();
    },
    async startAttendance() {
      let attendace = {
        startTime: Date.now(),
      };
      await this.createAttendance(attendace);

      this.snackbar = true;
      localStorage.setItem("started", true);
      this.started = true;
      this.fetchAttendanceSingleUser();
    },
    
  },
  computed: {
    ...mapGetters([
      "allLogin",
      "allAttedanceStatus",
    ]),
  },
  created() {
   
    this.started = localStorage.getItem("started");
    if (localStorage.getItem("token") == undefined) {
      // this.$router.push("/");
    } else {
      this.firstName = JSON.parse(localStorage.getItem("token")).user.firstName + " " + JSON.parse(localStorage.getItem("token")).user.lastName;
      if (
        JSON.parse(localStorage.getItem("token")).user.role != "admin" &&
        JSON.parse(localStorage.getItem("token")).user.role != "employee"
      ) {
        // this.$router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.logobox {
  margin: 10px;
  img {
    width: 100%;
  }
}
.backmenu {
  background: $menucolor !important;
  .v-list-item__title {
    color: $white;
  }
  .v-icon {
    color: $white;
  }
}

.rightsection {
  display: flex;
  width: 100%;
  list-style: none;
  padding-left: 0;
  justify-content: flex-end;
  align-items: center;
  text-transform: capitalize;
}
.whiteicon {
  color: $white !important;
}
.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 4px solid $white;
  background: $tagcolor;
  border-radius: 50%;
  color: $white;
  margin-right: 10px;
  font-weight: 600;
}
.leftsection {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
  li {
    margin-right: 10px;
    &:first-of-type {
      .v-btn {
        background: rgb(67, 82, 67);
        color: $white;
      }
    }
    &:last-of-type {
      .v-btn {
        background: rgb(3, 92, 3);
        color: $white;
      }
    }
  }
}
.iconclock {
  margin-right: 10px;
  color: $white !important;
}
</style>
