import '@material-design-icons/font';
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import ImageUploader from "vue-image-upload-resize";
import VueQuillEditor from "vue-quill-editor";
import VueSocialSharing from "vue-social-sharing";
import moment from "moment";
import DatetimePicker from "vuetify-datetime-picker";
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(DatetimePicker);

Vue.use(VueSocialSharing);

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

Vue.use(VueQuillEditor);

Vue.use(ImageUploader);

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD-MMM-YYYY");
  }
});
Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD-MMM-YYYY HH:MM:SS:A");
  }
});

Vue.config.productionTip = false;
export const baseUrl = new URL(
  "/api/v1/",
  process.env.VUE_APP_API_URL
).toString();

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ["localhost", /^https:\/\/.+\.innate-you\.com/],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
