import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  activity: undefined,
  activities: {
    results: [],
    pagination: {
      total: 0,
    }
  },
});

const getters = {
  allActivity: (state) => state.activity,
  allActivities: (state) => state.activities,
};

const actions = {
  async getActivites({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}activity?${new URLSearchParams(data).toString()}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setActivities", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
};

const mutations = {
  setActivity: (state, data) => (state.activity = data),
  setActivities: (state, data) => (state.activities = data),
};

export default { state, getters, actions, mutations };
