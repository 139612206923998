<template>
  <v-app id="app">
    <router-view :key="$route.fullPath"></router-view>
    <template v-if="allLogin.token != undefined">
        <v-snackbar v-model="snackbar">
          <span>
            Welcome {{ allLogin.user.fullname }}
          </span>
        </v-snackbar>
      </template>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "App",
  data: () => ({snackbar:false}),
  computed: {
    ...mapGetters(["allLogin"]),
  },
  created() {
    this.snackbar = true;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
html,body{
  padding: 0;
  margin: 0;
}
</style>
