import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  emotions: [],
  subEmotions: [],
  lifeTags: [],
  loadingCrud: false,
  currentEmotion: null,
  currentSubEmotion: null,
  currentLifeTag: null,
});

const getters = {
  allEmotions: (state) => state.emotions,
  allSubEmotions: (state) => state.subEmotions,
  allLifeTags: (state) => state.lifeTags,
  currentEmotion: (state) => state.currentEmotion,
  currentSubEmotion: (state) => state.currentSubEmotion,
  currentLifeTag: (state) => state.currentLifeTag,
  allLoadingCurd: (state) => state.loadingCrud,
};

const actions = {
  async fetchEmotions({ commit }, data = {}) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(`${baseUrl}emotion?sort=${data.sort || "sort"}`);
      commit("setEmotions", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchSubEmotions({ commit }) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(baseUrl + "subemotion");
      commit("setSubEmotions", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchLifeTags({ commit }) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(baseUrl + "lifetag");
      commit("setLifeTags", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchEmotionById({ commit }, id) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(baseUrl + `emotion/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("currentEmotion", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchSubEmotionById({ commit }, id) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(baseUrl + `subemotion/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("currentSubEmotion", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchLifeTagById({ commit }, id) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(baseUrl + `lifetag/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("currentLifeTag", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async addEmotion({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      await Axios.post(baseUrl + "emotion", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/emotions');
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async addSubEmotion({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      await Axios.post(
        baseUrl + "subemotion",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setSubEmotions", null);
      router.push('/dashboard/subemotions');
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async addLifeTag({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      await Axios.post(baseUrl + "lifetag", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/lifetags');
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async updateEmotion({ commit }, { id, data }) {
    try {
      this.dispatch("loadingCrubFunc");
      await Axios.put(baseUrl + `emotion/${id}`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/emotions');
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async updateSubEmotion({ commit }, { id, data }) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.put(`${baseUrl}subemotion/${id}`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/subemotions');
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async updateLifeTag({ commit }, { id, data }) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.put(`${baseUrl}lifetag/${id}`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push('/dashboard/lifetags');
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async swapEmotions({ commit }, { emotions, indices }) {
    try {
      this.dispatch("loadingCrubFunc");
      const emotion1 = emotions.results[indices[0]];
      const emotion2 = emotions.results[indices[1]];
      await Axios.put(baseUrl + `emotion/${emotion1.id}`, {
        sort: emotion2.sort,
      }, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      await Axios.put(baseUrl + `emotion/${emotion2.id}`, {
        sort: emotion1.sort,
      }, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      emotions.results[indices[0]] = emotion2;
      emotions.results[indices[1]] = emotion1;
      commit("setEmotions", emotions);
    } catch (error) {
      this.dispatch("logError", error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async loadingCrubFunc({ commit }) {
    commit("loadingCrubSet", true);
  },
  async loadingNotCrudFunc({ commit }) {
    commit("loadingNotCrudSet", false);
  },
};

const mutations = {
  setEmotions: (state, list) => (state.emotions = list),
  setSubEmotions: (state, list) => (state.subEmotions = list),
  setLifeTags: (state, list) => (state.lifeTags = list),
  currentEmotion: (state, detail) => (state.currentEmotion = detail),
  currentSubEmotion: (state, detail) => (state.currentSubEmotion = detail),
  currentLifeTag: (state, detail) => (state.currentLifeTag = detail),
  loadingCrubSet: (state, loading) => (state.loadingCrud = loading),
  loadingNotCrudSet: (state, loading) => (state.loadingCrud = loading),
};

export default { state, getters, actions, mutations };
