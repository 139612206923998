import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  report: undefined,
  reports: {
    results: [],
    pagination: {
      total: 0,
    }
  },
});

const getters = {
  allReport: (state) => state.report,
  allReports: (state) => state.reports,
};

const actions = {
  async getReports({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}report?${new URLSearchParams(data).toString()}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setReports", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },

};

const mutations = {
  setReport: (state, data) => (state.report = data),
  setReports: (state, data) => (state.reports = data),
};

export default { state, getters, actions, mutations };
