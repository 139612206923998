import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import dashboard from "./modules/dashboard";
import emotion from "./modules/emotion";
import challenge from "./modules/challenge";
import report from "./modules/report";
import community from "./modules/community";
import activity from "./modules/activity";
import prompter from "./modules/prompter";
import notification from "./modules/notification";
import error from "./modules/error";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    dashboard,
    emotion,
    challenge,
    report,
    community,
    activity,
    prompter,
    notification,
    error,
  },
});
