import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  challenge: {
    questions: []
  },
  challenges: {
    results: [],
    pagination: {
      total: 0,
    }
  },
});

const getters = {
  currentChallenge: (state) => state.challenge,
  allChallenges: (state) => state.challenges,
};

const actions = {
  async createChallenge({ commit }, { image, ...data }) {
    try {
      await Axios.post(`${baseUrl}challenge`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if (image) {
        const fd = new FormData();
        fd.append("image", image);
        await Axios.post(`${baseUrl}challenge/${id}/image`, fd, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
      }
      router.push('/dashboard/challenges');
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async updateChallenge({ commit }, { id, data: { image, ...data } }) {
    try {
      await Axios.put(`${baseUrl}challenge/${id}`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if (image) {
        const fd = new FormData();
        fd.append("image", image);
        await Axios.post(`${baseUrl}challenge/${id}/image`, fd, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
      } else if (image === null) {
        await Axios.delete(`${baseUrl}challenge/${id}/image`, {
          headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
      }
      router.push('/dashboard/challenges');
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async findChallenge({ commit }, id) {
    try {
      const response = await Axios.get(
        baseUrl + "challenge/" + id,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setChallenge", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async postChallengeQuestion(store, data) {
    const challenge = store.getters.currentChallenge;
    const challengeId = challenge._id;
    try {
      const response = await Axios.post(
        `${baseUrl}challenge/${challengeId}/questions`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      challenge.questions.push(response.data);
      store.commit("setChallenge", challenge);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async deleteChallengeQuestion(store, id) {
    const challenge = store.getters.currentChallenge;
    const challengeId = challenge._id;
    try {
      await Axios.delete(
        `${baseUrl}challenge/${challengeId}/questions/${id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      challenge.questions = challenge.questions.filter(q => q._id !== id);
      store.commit("setChallenge", challenge);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async applyCurrentQuestionsSort(store) {
    const challenge = store.getters.currentChallenge;
    const challengeId = challenge._id;
    const questionIds = challenge.questions.map(q => q._id);
    try {
      await Axios.post(`${baseUrl}challenge/${challengeId}/questions/sort`, {
        questionIds,
      }, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async updateChallengeQuestion(store, { id, data }) {
    const challenge = store.getters.currentChallenge;
    const challengeId = challenge._id;
    const question = challenge.questions.find(q => q._id === id);
    try {
      const response = await Axios.put(
        `${baseUrl}challenge/${challengeId}/questions/${id}`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      Object.assign(question, data);
      store.commit("setChallenge", challenge);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
  async fetchChallenges({ commit }, data) {
    try {
      const response = await Axios.get(
        `${baseUrl}challenge?${new URLSearchParams(data).toString()}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setChallenges", response.data);
    } catch (error) {
      this.dispatch("logError", error);
    }
  },
};

const mutations = {
  setChallenge: (state, data) => (state.challenge = data),
  setChallenges: (state, data) => (state.challenges = data),
};

export default { state, getters, actions, mutations };
