import Dashboard from "../views/dashboard/Dashboard";
const backendmenu = [
  {
    path: "/dashboard",
    component: Dashboard,
    children: [
      //table page
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/dashboard/views/Home"),
      },
     
   
      {
        path: "/dashboard/userlist",
        name: "User",
        component: () => import("../views/dashboard/views/User"),
      },
      {
        path: "/dashboard/emotions",
        name: "Emotion",
        component: () => import("../views/dashboard/views/EmotionList"),
      },
      {
        path: "/dashboard/lifetags",
        name: "Lifetag",
        component: () => import("../views/dashboard/views/LifeTagList"),
      },
      {
        path: "/dashboard/challenges",
        name: "Challenge",
        component: () => import("../views/dashboard/views/ChallengeList"),
      },
      {
        path: "/dashboard/communities",
        name: "Community",
        component: () => import("../views/dashboard/views/CommunityList"),
      },
      {
        path: "/dashboard/notifications",
        name: "Notification",
        component: () => import("../views/dashboard/views/NotificationList"),
      },
      {
        path: "/dashboard/notification/add",
        name: "AddNotification",
        component: () => import("../views/dashboard/views/single/AddNotification"),
      },
      {
        path: "/dashboard/activities",
        name: "Activity",
        component: () => import("../views/dashboard/views/Activity"),
      },
      {
        path: "/dashboard/subemotions",
        name: "Subemotion",
        component: () => import("../views/dashboard/views/SubEmotionList"),
      },
      {
        path: "/dashboard/users",
        name: "User",
        component: () => import("../views/dashboard/views/User"),
      },
      {
        path: "/dashboard/reports",
        name: "Reports",
        component: () => import("../views/dashboard/views/Report"),
      },
      {
        path: "/dashboard/emotion/add",
        name: "AddEmotion",
        component: () => import("../views/dashboard/views/single/EmotionDetail"),
      },
      {
        path: "/dashboard/emotion/:id",
        name: "AddEmotionUpd",
        component: () => import("../views/dashboard/views/single/EmotionDetail"),
      },
      {
        path: "/dashboard/challenges/add",
        name: "AddChallenge",
        component: () => import("../views/dashboard/views/single/ChallengeDetail"),
      },
      {
        path: "/dashboard/challenges/:id",
        name: "EditChallenge",
        component: () => import("../views/dashboard/views/single/ChallengeDetail"),
      },
      {
        path: "/dashboard/lifetags/add",
        name: "AddLifetag",
        component: () => import("../views/dashboard/views/single/LifeTagDetail"),
      },
      {
        path: "/dashboard/lifetags/:id",
        name: "EditLifetag",
        component: () => import("../views/dashboard/views/single/LifeTagDetail"),
      },
      {
        path: "/dashboard/subemotion/add",
        name: "AddSubemotion",
        component: () => import("../views/dashboard/views/single/SubEmotionDetail"),
      },
      {
        path: "/dashboard/prompter",
        name: "Prompter",
        component: () => import("../views/dashboard/views/PrompterList"),
      },
      {
        path: "/dashboard/prompter/add",
        name: "AddPrompter",
        component: () => import("../views/dashboard/views/single/PromptDetail"),
      },
      {
        path: "/dashboard/prompter/:id",
        name: "EditPrompter",
        component: () => import("../views/dashboard/views/single/PromptDetail"),
      },
      //update page
      {
        path: "/dashboard/subemotion/:id",
        name: "AddSubemotionUpd",
        component: () => import("../views/dashboard/views/single/SubEmotionDetail"),
      },
      
    ],
  },
];

export default backendmenu;
